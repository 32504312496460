import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50')
];

export const server_loads = [0,2,7];

export const dictionary = {
		"/": [~11],
		"/(authed)/create-study": [~12,[2],[3]],
		"/(authed)/default": [~13,[2],[3]],
		"/(authed)/finance": [~14,[2,4],[3]],
		"/(authed)/finance/[studyId]": [~15,[2,4],[3]],
		"/(authed)/finance/[studyId]/create-invoice": [~16,[2,4],[3]],
		"/(authed)/finance/[studyId]/create-remittance": [~17,[2,4],[3]],
		"/(authed)/finance/[studyId]/create-site-cost": [~18,[2,4],[3]],
		"/(authed)/finance/[studyId]/reconcile-remittance-new/[remittanceId]": [~20,[2,4],[3]],
		"/(authed)/finance/[studyId]/reconcile-remittance-procedure/[remittanceId]": [~21,[2,4],[3]],
		"/(authed)/finance/[studyId]/reconcile-remittance/[remittanceId]": [~19,[2,4],[3]],
		"/(authed)/finance/[studyId]/update-invoice/[invoiceId]": [~22,[2,4],[3]],
		"/(authed)/finance/[studyId]/update-remittance/[remittanceId]": [~23,[2,4],[3]],
		"/(authed)/finance/[studyId]/update-site-cost/[chargeItemId]": [~24,[2,4],[3]],
		"/(authed)/finance/[studyId]/view-invoiceable": [~25,[2,4],[3]],
		"/(authed)/finance/[studyId]/view-site-cost/[chargeItemDefinitionId]": [~26,[2,4],[3]],
		"/(authed)/finance/[studyId]/view-visit/[visitId]": [~27,[2,4],[3]],
		"/login": [~50,[9],[10]],
		"/(authed)/patients": [~28,[2,5],[3]],
		"/(authed)/patients/create-patient": [29,[2,5],[3]],
		"/(authed)/patients/update-patient/[patientId]": [~30,[2,5],[3]],
		"/(authed)/settings": [31,[2,6],[3]],
		"/(authed)/system": [~32,[2,7],[3]],
		"/(authed)/system/[organizationId]": [~33,[2,7],[3]],
		"/(authed)/system/[organizationId]/build-study": [~34,[2,7],[3]],
		"/(authed)/system/[organizationId]/study/[studyId]": [~35,[2,7],[3]],
		"/(authed)/system/[organizationId]/study/[studyId]/budget": [~36,[2,7],[3]],
		"/(authed)/system/[organizationId]/study/[studyId]/schedule": [~37,[2,7],[3]],
		"/(authed)/system/[organizationId]/study/[studyId]/visit-definition/[visitDefId]": [~38,[2,7],[3]],
		"/(authed)/visit-tracking": [~39,[2,8],[3]],
		"/(authed)/visit-tracking/[studyId]": [~40,[2,8],[3]],
		"/(authed)/visit-tracking/[studyId]/create-subject": [41,[2,8],[3]],
		"/(authed)/visit-tracking/[studyId]/create-visit/[subjectId]": [~42,[2,8],[3]],
		"/(authed)/visit-tracking/[studyId]/review-stipends/[subjectId]": [~43,[2,8],[3]],
		"/(authed)/visit-tracking/[studyId]/tab-review": [~44,[2,8],[3]],
		"/(authed)/visit-tracking/[studyId]/tab-schedule": [~45,[2,8],[3]],
		"/(authed)/visit-tracking/[studyId]/unplanned-visits/[subjectId]": [~46,[2,8],[3]],
		"/(authed)/visit-tracking/[studyId]/update-study": [~47,[2,8],[3]],
		"/(authed)/visit-tracking/[studyId]/update-subject/[subjectId]": [~48,[2,8],[3]],
		"/(authed)/visit-tracking/[studyId]/update-visit/[visitId]": [~49,[2,8],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';