import { log } from '@flint/logger';
import * as Sentry from '@sentry/sveltekit';
import { dev } from '$app/environment';
import { getAppEnvironment, getAppVersion } from '$lib/utils/env';

log.setLogLevel(dev ? 'trace' : 'info');

Sentry.init({
    dsn: 'https://c69abde2165a741d31e4399cc7bf6321@o4506547865190400.ingest.sentry.io/4506553255133184',
    enabled: !dev, // disable capturing exceptions in development
    release: getAppVersion(),
    environment: getAppEnvironment(),
    tracesSampleRate: 1,
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 1,
    integrations: [
        // eslint-expect-error - `import/namespace` doesn't understand the exported values correctly
        // eslint-disable-next-line import/namespace
        new Sentry.Replay({ stickySession: false }),
        // eslint-disable-next-line import/namespace
        new Sentry.Feedback({ autoInject: false, showBranding: false }),
    ],
});
if (!dev) {
    // Whenver @flint/logger logs an error, also send it as an Exception to Sentry.
    const DEFAULT_TRANSPORT = log.transportFactory;
    log.setTransport({
        trace: DEFAULT_TRANSPORT.trace,
        debug: DEFAULT_TRANSPORT.trace,
        info: DEFAULT_TRANSPORT.info,
        warn: DEFAULT_TRANSPORT.warn,
        error: (pid, module, payload: unknown[]) => {
            if (!dev) {
                const error = payload.find((x: unknown) => x instanceof Error);
                if (error) {
                    Sentry.captureException(error);
                } else if (typeof payload[0] === 'string') {
                    Sentry.captureMessage(payload.map((x) => String(x)).join(' '));
                }
            }
            DEFAULT_TRANSPORT.error(pid, module, payload);
        },
    });
}

// The handleError hook runs when an unexpected error is thrown while responding to a request.
//
// If an unexpected error is thrown during loading or rendering,
// this function will be called with the error and the event.
export const handleError = !dev ? Sentry.handleErrorWithSentry() : undefined;
